<template>
  <div>
    <section class="section medium">
      <div class="container">
        <h2 class="section-title center">{{$t("services")}}</h2>
        <div class="w-dyn-list">
          <div role="list" class="integration-grid w-dyn-items">
            <!--
            <div class="integration-collection-item w-dyn-item" v-for="(item, index) in products">
              <router-link :to="'/service/' + item.sys_name" class="integration-block w-inline-block">
                <div class="integration-flex-wrap">
                  <img :src="'../../services/' + item.path_img" alt="" class="integration-avatar"/>

                  <div v-if="item.name_product != undefined" class="integration-title">{{ item.name_product }}</div>
                  <div v-else class="integration-title">{{ item.title }}</div>
                </div>
                <p v-html="item.desc"></p>
                <div class="btn-arrow">
                  <div class="btn-arrow-text">{{ $t("integrate_now") }}</div>
                  <img src="https://assets.website-files.com/5f0763bf575ff70f2f96ccaa/5f077a6ea949dfb6a02f5bf6_arrow-right-white.svg" alt="" class="filled-btn-arrow-icon in-blog-list-view"/>
                </div>
              </router-link>
            </div>
            -->
            <div class="integration-collection-item w-dyn-item" v-for="(item, index) in products" :key="index">
              <a class="integration-block w-inline-block">
                <div class="integration-flex-wrap">
                  <img v-if="item.path_img != '' && item.path_img != undefined" :src="`../../services/${item.path_img}`" alt="" class="integration-avatar"/>
                  <img v-else :src="`../../services/${item.sys_name}.png`" alt="" class="integration-avatar"/>
                  <div v-if="item.name_product != undefined" class="integration-title">
                    {{ item.name_product }}
                  </div>
                  <div v-else class="integration-title">
                    {{ item.title }}
                  </div>
                </div>
                <p class="short_desc" v-show="products[index].show_short == undefined || products[index].show_short == true" v-html="item.short_desc"></p>
                <p class="full_desc" v-show="products[index].show_full" v-html="item.full_desc">
                </p>
                <div v-show="products[index].show_short == undefined || products[index].show_short == true" class="btn-arrow-text find_out_more" @click="showFull(index)">{{$t("find_out_more")}}</div>
                <div v-show="products[index].show_full" class="btn-arrow-text find_out_more" @click="showFull(index)">{{$t("find_out_less")}}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section medium">
      <div class="container">
        <h2 class="section-title center">{{$t("we_currently_support_following_messengers")}}</h2>
        <div class="w-dyn-list">
          <div role="list" class="integration-grid w-dyn-items">
            <!--
            <div class="integration-collection-item w-dyn-item" v-for="(item, index) in message">
              <div class="integration-block w-inline-block">
                <div class="integration-flex-wrap">
                  <img :src="'../../services/' + item.path_img" alt="" class="integration-avatar"/>
                  <div v-if="item.name_product != undefined" class="integration-title">{{ item.name_product }}</div>
                  <div v-else class="integration-title">{{ item.title }}</div>
                </div>
                <p v-html="item.desc"></p>
              </div>
            </div>
            -->
          <div class="integration-collection-item w-dyn-item" v-for="(item, index) in messages" :key="index">
            <a class="integration-block w-inline-block">
              <div class="integration-flex-wrap">
                <img v-if="item.path_img != '' && item.path_img != undefined" :src="`../../services/${item.path_img}`" alt="" class="integration-avatar"/>
                <img v-else :src="`../../services/${item.sys_name}.png`" alt="" class="integration-avatar"/>
                <div v-if="item.name_product != undefined" class="integration-title">
                  {{ item.name_product }}
                </div>
                <div v-else class="integration-title">
                  {{ item.title }}
                </div>
              </div>
              <p class="short_desc" v-show="messages[index].show_short == undefined || messages[index].show_short == true" v-html="item.short_desc"></p>
              <p class="full_desc" v-show="messages[index].show_full" v-html="item.full_desc">
              </p>
              <div v-show="messages[index].show_short == undefined || messages[index].show_short == true" class="btn-arrow-text find_out_more" @click="showMessageFull(index)">{{$t("find_out_more")}}</div>
              <div v-show="messages[index].show_full" class="btn-arrow-text find_out_more" @click="showMessageFull(index)">{{$t("find_out_less")}}</div>
            </a>
          </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
	  import axios from "axios";
    import config from "@/store/config";
    import bus from '@/store/bus'

    export default {
		  name: "Relate",
      data() {
        return {
          products : [],
          messages : []
        }
      },
      mounted() {
        this.refreshData();
        bus.$on("re_render", this.refreshData);
      },
      beforeUpdate() {
      },
      updated() {
      },
      methods : {
		    refreshData : function () {
          this.getListService();
          this.getListMessage();
        },
        getListService : function () {
          axios({
            method: "POST",
            url: `${config.getters.mainUrl}service/listServices?ts=${Math.round(+new Date() / 1000)}`,
            data: {
              lang :  this.$cookie.get('elza_lng')
            },
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': '0',
            }
          }).then(result => {
            if (result != undefined && result.data != undefined && result.data.result != undefined) {
              if (result.data.result == true) {
                //this.select_lang = result.data.data[0].language_name;
                //this.available_services = result.data.data["available_services"];
                //this.additional_services = result.data.data["additional_services"];
                this.products = result.data.data;
                //this.list_service = [].concat(this.available_services, this.product);
              }
            }
          }, error => {
            console.log(error);
          })
        },
        getListMessage : function () {
          axios({
            method: "POST",
            url: `${config.getters.mainUrl}service/listMessages?ts=${Math.round(+new Date() / 1000)}`,
            data: {
              lang :  this.$cookie.get('elza_lng')
            },
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': '0',
            }
          }).then(result => {
            if (result != undefined && result.data != undefined && result.data.result != undefined) {
              if (result.data.result == true) {
                //this.select_lang = result.data.data[0].language_name;
                //this.available_services = result.data.data["available_services"];
                //this.additional_services = result.data.data["additional_services"];
                this.messages = result.data.data;
                //this.list_service = [].concat(this.available_services, this.product);
              }
            }
          }, error => {
            console.log(error);
          })
        },
        showFull : function (item) {
          console.log(item, this.products[item].show_full, this.products[item].show_short );
          if (this.products[item].show_full == true) {
            this.products[item].show_short = true;
            this.products[item].show_full = false;
          } else {
            this.products[item].show_short = false;
            this.products[item].show_full = true;
          }
        },
        showMessageFull : function (item) {
          console.log(item, this.messages[item].show_full, this.messages[item].show_short );
          if (this.messages[item].show_full == true) {
            this.messages[item].show_short = true;
            this.messages[item].show_full = false;
          } else {
            this.messages[item].show_short = false;
            this.messages[item].show_full = true;
          }
        }
      }
	  }
</script>

<style lang=scss>
.short_desc {
  height: 80px;
}

.find_out_more {
  float: right;
}

.find_out_more {
  cursor: pointer;
  margin-top: 30px;
  font-size: 12px;
  color: #ffdfdf;
}

</style>
